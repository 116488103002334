import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'
const AuthContext = createContext({})


const AuthProvider = (props) => {

    const [data, setData] = useState({
        // id: 1,
        // nombre: "joaquin",
        // role: ['boss'],
        // permissions: ["admin"]
    })

    // useEffect(() => {
    //     axios.get('https://cobros.nodosapi.com.ar/colegio', {
    //         method: 'GET',
    //         headers: {
    //             "Authorization":
    //                 `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJub2Rvc2h1Yi5jb20iLCJzdWIiOiJOb2RvcyIsImlhdCI6MTY2NzMxNjEyNSwiZXhwIjoxNjcxNjM2MTI1LCJkYXRhIjoiZXlKcGRpSTZJblpSWWxBM2RuRkZiR3Q2UzFSWU9IZDRiRUZIWkhjOVBTSXNJblpoYkhWbElqb2lUMkZtUmpSaWNYbFRXRXRwZDFwMFVDdEdibGRrTkU5cGNuVkNlWE0zZUhvcllWZHpOVWhpYVM4dmN6WTNMM2RRTldSak5VcE5iM1ZJTlZSUVVscG5VVmxyYWtObVNWcDVUVFl2U1ZkcWVrVlJPRWhXZHl0alpXNDJPWFpSY1M5eFF6RktTbTlCSzI0MlVuaGFPR1ZTZWtoVVIzVTFOV3A2WkUxRmFVUXJVRVJsVFU5RWNtMDVOamt4ZEhKS00waExSR1p6Vm1waFkwVjJaMGRDVUc5VlYzTm1jbloyT0dnNU56VTBQU0lzSW0xaFl5STZJbVZsTlRJNU1ESTNaVEkwTkdFek4yUTJPV1poTm1Fd1lUWXhaRFV5WWpGa016VXhOamRqT1RRMVlXSmhNV001WVRVellUbG1NemMwWlRnM09ERXpNRE1pTENKMFlXY2lPaUlpZlE9PSJ9.wL_s17KTwYmCvzFgnndI29ttk0ABv3S4vI3BQRKutyw`,

    //         },
    //         withCredentials: true,
    //     })
    //         .then(response => response.data)
    //         .then((datos) => {
    //             setData(datos)
    //             // console.log(datos)
    //         })
    //         .catch((err) => {
    //             console.log(err)

    //         })
    // }, [])



    // const authContextValue = {
    //     setData
    // }


    return <AuthContext.Provider value={data} {...props} />
}

const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }
