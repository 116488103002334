import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from './Auth-context';
const BecasAlumno = lazy(() => import("./Componentes/BecasAlumno/BecasAlumno"))
const ListadoAlumnos = lazy(() => import("../src/Componentes/ListadoAlumnos/ListadoAlumnos.jsx"))
const AlumnosAsignados = lazy(() => import("./Componentes/AlumnosAsignados/AlumnosAsignados"))
const Pagos = lazy(() => import("./Componentes/Pagos/Pagos"))
const RazonSocial = lazy(() => import("./Componentes/RazonSocial/RazonSocial"))
const AddRazon = lazy(() => import("./Componentes/AddRazon/AddRazon"))
const Responsables = lazy(() => import("./Componentes/Responsables/Responsables"))
const AgregarDebito = lazy(() => import("./Componentes/Debito/AgregarDebito/AgregarDebito"))
const AdheridosDebito = lazy(() => import("./Componentes/AdheridosDebito/AdheridosDebito.jsx"))
const ListarDeudas = lazy(() => import("./Componentes/ListarDeudas/listarDeudas.jsx"))
const Nav = lazy(() => import("./Componentes/nav/Nav.js"))
const ListarIngresos = lazy(() => import("./Componentes/ListarIngresos/ListarIngresos"))
const Cheques = lazy(() => import("./Componentes/Cheques/Cheques.jsx"))
const ListarPagos = lazy(() => import("./Componentes/ListaPago/ListarPagos"))
const EntidadesFinancieras = lazy(() => import("./Componentes/EntidadesFinancieras/EntidadesFinancieras"))
const Saldos = lazy(() => import("./Componentes/Saldos/Saldos"))
const CierreCaja = lazy(() => import("./Componentes/Cierre/CierreCaja"))
const Lista = lazy(() => import("./Componentes/NotasCredito/Lista.jsx"))
const Extraordinarios = lazy(() => import("./Componentes/Extraordinarios/Extraordinarios"))
const Conceptos = lazy(() => import("./Componentes/Conceptos/Conceptos.jsx"))
const Debito = lazy(() => import("./Componentes/Debito/Debito"))
const Menu = lazy(() => import("./Componentes/Menu/Menu"))
const Categorias = lazy(() => import("./Componentes/Categorias/Categorias"))
const Tutores = lazy(() => import("./Componentes/Tutores/Tutores.jsx"))
const Importes = lazy(() => import("./Componentes/Importes/Importes"))
const Recargos = lazy(() => import("./Componentes/Recargos/Recargos.jsx"))
const AgregarImporteGeneral = lazy(() => import("./Componentes/AgregarImporteGeneral/AgregarImporteGeneral.jsx"))
const Becas = lazy(() => import("./Componentes/Becas/Becas.jsx"))
const Medios = lazy(() => import("./Componentes/Medios/Medios"))
const Admin = lazy(() => import("./Componentes/Admin/Home/Admin"))
const EditAdheridos = lazy(() => import('./Componentes/EditAdherido/EditAdherido.jsx'))
const ConceptosPagados = lazy(() => import('./Componentes/ConceptosPagados/ConceptosPagados.jsx'))
const ConceptAPagar = lazy(() => import('./Componentes/ConceptAPagar/ConceptAPagar.jsx'))

function App() {


  return (
    <AuthProvider>
      <Router>
        <Suspense fallback={<>Loading...</>}>
          <Menu />
          <Routes>
            <Route
              path='/'
              element={
                <Nav />
              }
            />
            <Route
              path='/alumnos/:id'
              element={
                <ListadoAlumnos />
              }
            />
            <Route
              path='/Tutores'
              element={
                <Tutores />
              }
            />
            <Route
              path='/Conceptos'
              element={
                <Conceptos />
              }
            />
            <Route
              path='/Categorias'
              element={
                <Categorias />
              }
            />
            <Route
              path='/Importes'
              element={
                <Importes />
              }
            />
            <Route
              path='/AgregarImporteGeneral'
              element={
                <AgregarImporteGeneral />
              }
            />
            <Route
              path='/Recargos'
              element={
                <Recargos />
              }
            />
            <Route
              path='/Becas'
              element={
                <Becas />
              }
            />
            <Route
              path='/BecasAlumno/:id'
              element={
                <BecasAlumno />
              }
            />
            <Route
              path='/AlumnosAsignados/:id'
              element={
                <AlumnosAsignados />
              }
            />
            <Route
              path='/Entidades'
              element={
                <EntidadesFinancieras />
              }
            />
            <Route
              path='/Debito'
              element={
                <Debito />
              }
            />
            <Route
              path='/Cheques'
              element={
                <Cheques />
              }
            />
            <Route
              path='/Medios'
              element={
                <Medios />
              }
            />
            <Route
              path='/pagos/:idPost'
              element={
                <Pagos />
              }
            />
            <Route
              path='/listaPagos'
              element={
                <ListarPagos />
              }
            />
            <Route
              path='/razon'
              element={
                <RazonSocial />
              }
            />
            <Route
              path='/addRazon'
              element={
                <AddRazon />
              }
            />
            <Route
              path='/saldos'
              element={
                <Saldos />
              }
            />
            <Route
              path='/extraordinario'
              element={
                <Extraordinarios />
              }
            />
            <Route
              path='/cierre'
              element={
                <CierreCaja />
              }
            />
            <Route
              path='/responsables'
              element={
                <Responsables />
              }
            />
            <Route
              path='/listarDeudas'
              element={
                <ListarDeudas />
              }
            />
            <Route
              path='/listarIngresos'
              element={
                <ListarIngresos />
              }
            />
            <Route
              path='/admin'
              element={
                <Admin />
              }
            />
            <Route
              path='/addDebito'
              element={
                <AgregarDebito />
              }
            />
            <Route
              path='/adheridos'
              element={
                <AdheridosDebito />
              }
            />
            <Route
              path='/listaNotas'
              element={
                <Lista />
              }
            />
            <Route
              path='/adheridos/:id'
              element={
                <EditAdheridos />
              }
            />
            <Route
              path='/ConceptosPagados'
              element={
                <ConceptosPagados />
              }
            />
            <Route
              path='/ConceptosaPagar'
              element={
                <ConceptAPagar />
              }
            />
          </Routes>
        </Suspense>

      </Router>
    </AuthProvider>


  );
}

export default App;
